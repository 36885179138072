.ticket-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--grey-300);
	text-align: center;
	margin-bottom: 2rem;
}

.ticket-content > h2 {
	font-weight: var(--font-semibold);
	font-size: var(--h6);
	line-height: 38px;
	color: var(--yellow-300);
}

.ticket-content > p {
	font-weight: var(--font-normal);
	font-size: var(--body-text);
	line-height: 38px;
}

.pricingTable {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 64px;
}

.plancard {
	text-align: center;
	display: flex;
	flex-direction: column;
	border: 2px solid var(--brown-75);
	border-radius: 16px;
	gap: 48px;
	padding: 48px 57px;
}
.plancard .button {
	width: inherit;
}

.plancard:nth-child(2) {
	background-color: var(--red-200);
	border: none;
}

.planTitle {
	font-weight: var(--font-medium);
	font-size: var(--h4);
	line-height: 56px;
	width: 200px;
}

.planPrice {
	font-weight: var(--font-semibold);
	font-size: var(--h2);
	line-height: 120px;
}
.planDescription {
	font-weight: var(--font-normal);
	font-size: var(--body-text);
	line-height: 32px;
}
.planHeader {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 42px;
}

/* Laptop Styling */
@media only screen and (min-width: 900px) {
	.ticket-content > h2 {
		font-size: var(--h4);
		line-height: 64px;
	}

	.ticket-content > p {
		font-size: var(--h5);
	}
	/* .plancard {
		flex-grow: 1;
	} */
	.planTitle {
		width: 320px;
	}
	.pricingTable {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		gap: 64px;
	}
	.plancard {
		border-radius: 32px;
	}
}

/* small-mobile Styling */
@media only screen and (max-width: 280px) {
	.plancard {
		min-width: 160px;
		padding: 48px 12px;
	}
	.planTitle {
		width: 240px;
	}
}

/* Larger Styling */
@media only screen and (min-width: 1200px) {
	.plancard {
		min-width: 300px;
	}
	.pricingTable {
		flex-direction: row;
		flex-wrap: nowrap;
		gap: 24px;
	}
}
