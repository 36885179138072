.subscribe {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--brown-300);
	background-image: url('../../assets/bg-texture/texture.svg');
	background-repeat: no-repeat;
	background-size: cover;
	height: 450px;
}

.subscribe-container {
	display: flex;
	flex-direction: column;
	font-weight: var(--font-normal);
	gap: 32px;
}

.subscribe-container h2 {
	font-size: var(--h4);
	font-weight: var(--font-normal);
	font-weight: inherit;
	color: var(--grey-50);
	line-height: 56px;
}

.subscribe-button .button:hover {
	background-color: var(--yellow-200);
	width: 0px;
}

.subscribe-button-container {
	display: flex;
	justify-content: center;
}

/* Overide Component button styles for proper fit*/
.subscribe-button-container .button {
	color: var(--brown-50);
	padding: 8px 32px;
	font-size: var(--h6);
	font-weight: var(--font-normal);
	line-height: 32px;
	width: auto;
}

.subscribe-button-container .button:hover {
	background-color: var(--yellow-200);
}

/* Mobile Styling*/
@media only screen and (max-width: 480px) {
	.subscribe {
		height: 280px;
		align-items: center;
		background-size: cover;
		background-image: url('../../assets/bg-texture/mobileTexture.svg');
	}
	.subscribe-container {
		margin: 0;
		width: 342px;
		gap: 16px;
	}
	.subscribe-container h2 {
		font-size: var(--h6);
		text-align: center;
		line-height: 38px;
		font-weight: var(--font-normal);
	}

	.subscribe-button-container .button {
		font-size: var(--body-text);
	}
}

/* Tablet Styling */

@media only screen and (max-width: 768px) {
	.subscribe-container h2 {
		text-align: center;
	}
}
