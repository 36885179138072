.sponsor-section-title {
	font-size: var(--h5);
	font-weight: var(--font-medium);
	color: var(--grey-300);
	line-height: 48px;
	text-align: center;
}

.sponsor-packages-wrapper {
	display: flex;
	flex-direction: column;
	width: inherit;
	gap: 48px;
}
.sponsor-package-container {
	display: flex;
	flex-direction: column;
	width: inherit;
	gap: 32px;
}
.sponsor-package-container > h3 {
	font-size: var(--h6);
	line-height: 38px;
	font-weight: var(--font-medium);
	text-align: center;
}
.sponsors-logo-container {
	text-decoration: none;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-direction: column;
	gap: 20px;
}

.sponsor-logo-wrapper {
	display: flex;
	justify-content: center;
	width: inherit;
	padding: 6px;
	margin-top: 15px;
}

.sponsor-logo {
	max-width: 200px;
	max-height: 200px;
}

.prospectus-container {
	display: flex;
	flex-direction: column;
	gap: 43px;
	margin-top: 20px;
}

.prospectus-container > p {
	font-size: var(--body-text);
	font-weight: var(--font-normal);
	color: var(--grey-300);
}
.prospectus-container > a {
	width: fit-content;
	background-color: white;
	padding: 8px 16px;
	border-radius: 4px;
	border: 1px solid var(--yellow-300);
	color: var(--yellow-300);
	font-size: var(--body-text);
	font-weight: var(--font-normal);
	line-height: 32px;
}
.prospectus-container > a:hover {
	color: var(--yellow-100);
	border-color: var(--yellow-100);
	background-color: white;
}

@media only screen and (min-width: 900px) {
	.sponsor-section-title {
		font-size: var(--h3);
		font-weight: var(--font-semibold);
		line-height: 72px;
	}
	.sponsor-packages-wrapper {
		gap: 48px;
	}
	.sponsor-package-container {
		align-items: center;
		gap: 0px;
	}
	.sponsor-package-container > h4 {
		font-size: var(--h4);
		font-weight: var(--font-medium);
		line-height: 56px;
	}
	.sponsors-logo-container {
		flex-direction: row;
		align-items: center;
		gap: 48px;
		text-align: center;
	}

	.sponsor-logo-wrapper {
		flex-grow: 1;
	}
	.sponsor-logo {
		max-width: 300px;
		max-height: 300px;
	}
	.prospectus-container {
		display: flex;
		flex-direction: column;
		gap: 43px;
	}

	.prospectus-container > p {
		font-size: var(--h6);
		font-weight: var(--font-normal);
		color: var(--grey-300);
	}
	.prospectus-container > a {
		width: fit-content;
		padding: 8px 16px;
		border-radius: 4px;
		background-color: white;
		border: 1px solid var(--yellow-300);
		color: var(--yellow-300);
		font-size: var(--body-text);
		font-weight: var(--font-normal);
		line-height: 32px;
	}
	.prospectus-container > a:hover {
		color: var(--yellow-100);
		border-color: var(--yellow-100);
		background-color: white;
	}
}
