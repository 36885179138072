.about {
	display: flex;
	flex-direction: column;
	color: var(--grey-300);
	gap: 0.5rem;
	margin-top: 8rem;
}

.about-content p {
	font-size: var(--body-text);
	font-weight: var(--font-normal);
	line-height: 32px;
	margin-bottom: 1rem;
}

.about-content .button {
	color: white;
}

.about-title {
	font-weight: var(--font-medium);
	font-size: var(--h5);
	line-height: 48px;
}

.about-title br {
	display: inline;
	content: ' ';
	padding: 0 5px;
}

/* Large Screen/Laptop Styling */
@media only screen and (min-width: 769px) {
	.about-title br {
		display: block;
	}
	.about {
		flex-direction: row;
		align-items: start;
		gap: 3rem;
	}

	.about-title {
		font-weight: var(--font-semibold);
		font-size: var(--h3);
		line-height: 72px;
		width: 100%;
		white-space: nowrap;
	}
	.about-content {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
	.about-content p {
		font-size: var(--h6);
	}
	.about-content .button {
		color: var(--white);
		max-width: fit-content;
	}
}
