.markdown-content {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	padding: 10px;
	gap: 32px;
	font-size: var(--h6);
	font-weight: var(--font-normal);
	line-height: 38px;
}
.markdown-content a {
	color: blue;
}

.markdown-content p,
li,
ul,
span {
	font-size: var(--body-text);
	font-weight: var(--font-normal);
	line-height: 32px;
	margin-bottom: 1rem;
}

.markdown-content h1 {
	font-weight: var(--font-medium);
	font-size: var(--h5);
	line-height: 48px;
}

/* Large Screen/Laptop Styling */
@media only screen and (min-width: 769px) {
	.markdown-content p,
	li,
	ul,
	span {
		font-size: var(--h6);
		line-height: 50px;
	}
	.markdown-content h1 {
		font-weight: var(--font-semibold);
		font-size: var(--h3);
		line-height: 72px;
		width: 100%;
	}
}
