.activity-section {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 5rem;
	color: var(--grey-300);
}

.activity-section br {
	display: inline;
	content: ' ';
}
.activity-section > h2 {
	font-weight: var(--font-medium);
	font-size: var(--h5);
	line-height: 48px;
}

.activities {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;
}

.activity {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	width: 280px;
}

.activity img {
	border-radius: 48px;
	width: 280px;
}

.activity h3 {
	font-weight: var(--font-semibold);
	font-size: var(--h6);
	line-height: 38px;
}

.activity p {
	text-align: center;
	font-weight: var(--font-normal);
	font-size: var(--body-text);
	line-height: 32px;
}

/* Tablet Styling */
@media only screen and (min-width: 480px) {
	.activity-section br {
		display: block;
	}
}

@media only screen and (max-width: 280px) {
	.activity-section {
		gap: 2rem;
	}
	.activity {
		width: 100%;
	}
	.activity img {
		width: 100%;
	}
}

/* Laptop Styling */
@media only screen and (min-width: 769px) {
	.activity-section > h2 {
		font-weight: var(--font-semibold);
		font-size: var(--h4);
		line-height: 56px;
		white-space: nowrap;
	}
	.activity {
		width: 350px;
	}

	.activity img {
		width: 350px;
	}
	.activities {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: start;
	}
	.activity h3 {
		font-size: var(--h5);
		line-height: 64px;
	}

	.activity p {
		font-size: var(--h6);
		line-height: 38px;
	}
}

/* Larger Styling */
@media only screen and (min-width: 1200px) {
	.activity {
		min-width: 300px;
	}
	.activities {
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
}
