.venue-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--grey-300);
	gap: 3rem;
}

.venue-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
}

.venue-content > h2 {
	font-weight: var(--font-medium);
	font-size: var(--h5);
	line-height: 48px;
}

.venue-content > p {
	font-weight: var(--font-normal);
	font-size: var(--body-text);
	line-height: 32px;
}

.venue-content .button {
	color: white;
	width: fit-content;
}

.venue-photo {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 100%;
}

.venue-photo img {
	width: 100%;
	height: auto;
}

@media only screen and (min-width: 769px) {
	.venue-content > h1 {
		font-weight: var(--font-semibold);
		font-size: var(--h4);
		line-height: 56px;
		white-space: nowrap;
	}
	.venue-section {
		flex-direction: row;
		gap: 5rem;
		width: 100%;
	}

	.venue-content {
		justify-content: center;
		max-width: 50%;
	}

	.venue-content > p {
		font-size: var(--h6);
		line-height: 38px;
	}

	.venue-photo img {
		min-width: 400px;
	}
}