.header-container {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 90;
	padding: 1rem 0;
}
.header-wrapper{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	left: 0;
	top: 0;
	width: 100%;
	display: relative;
}

.header-scroll{	
	background-color: var(--white);
	color: var(--grey-300);
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.logo {
	width: 71px;
	height: 37.13px;
}
.language-type {
	display: flex;
	align-items: center;
	gap: 11px;
}


.activate-navbar {
	position: absolute;
	display: grid;
	grid-template-rows: 2rem 1fr 1fr;
	padding: 24px;
	gap: 25px;
	width: 70%;
	right: 0;
	top: 0;
	z-index: 50;
	background-color: var(--white);
	height: 433px;
	box-shadow: 0px 4px 16px rgba(52, 52, 52, 0.1);
	display: none;
}

.navbar-animate {
	opacity: 0;
	display: grid;
	transform: translateX(200px);
   	animation: fadeIn 0.5s ease-in-out forwards;
}
.navbar-animate-close {
	opacity: 1;
}

@keyframes fadeIn {
	to {
	  opacity: 1;
	  transform: translateX(0);
	}
  }
.button-container {
	display: flex;
	width: 100%;
	padding-top: 10px;
	justify-content: space-between;
	flex-direction: column;
	order: 3;
}

.language-container {
	order: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.mobile-container {
	display: flex;
	justify-content: flex-end;
}
.button-container .button {
	min-width: inherit;
}
.button-container .button:nth-child(2) {
	order: 2;
}
.button-container .button:nth-child(1) {
	background-color: white;
	border: 1px solid;
	border-radius: 4px;
	font-weight: var(--font-medium);
	border-color: var(--grey-200);
	color: var(--grey-200);
}

.button-container .button:nth-child(2) {
	border-radius: 4px;
	border-color: var(--grey-200);
	color: var(--white);
}

.button-container .button:nth-child(1):hover {
	background-color: var(--yellow-200);
	border: none;
}

.button-container .button:nth-child(1):hover {
	color: var(--grey-100);
	border-color: var(--grey-100);
}

/* Laptop Styling */

@media only screen and (min-width: 930px) {
	.header-container {
		width: 100%;
		padding: 1rem 4rem;
	}
	.header-wrapper {
		grid-template-columns: 1fr 2.3fr;
		gap: 5rem;
		z-index: 90;
	}

	.header-scroll{
		background-color: var(--white);
	}
	.navigation-wrapper {
		position: relative;
		align-items: center;
		right: 0;
		background: none;
		height: fit-content;
		display: grid;
		grid-template-rows: none;
		background-color: none;
		box-shadow: none;
		width: fit-content;
		padding: 0;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 4rem;
	}
	.button-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 32px;
		margin: 0;
		padding: 0;
		justify-content: flex-end;
		order: 2;
	}

	.mobile-container {
		display: none;
	}

	.language-container {
		order: 2;
		display: flex;
		flex-direction: row;
		gap: 15px;
		align-items: center;
	}

	.language-container > p {
		font-size: var(--body-text);
		font-weight: var(--font-medium);
	}

	.logo {
		width: 131px;
		height: 67.55px;
	}

	.button-container .button:nth-child(1) {
		background-color: transparent;
		border: 1px solid;
		border-radius: 4px;
		width: fit-content;
		white-space: nowrap;
		padding: 8px 16px;
		font-weight: var(--font-medium);
		border-color: var(--grey-200);
		color: var(--grey-200);
		line-height: 40px;
		order: 1;
	}

	.button-container .button:nth-child(1):hover {
		color: var(--grey-100);
		border-color: var(--grey-100);
		border: 1px solid;
		background-color: var(--white);
	}

	.button-container .button:nth-child(2) {
		gap: 8px;
		border-radius: 4px;
		border-color: var(--grey-200);
		padding: 8px 32px;
		line-height: 40px;
		color: var(--white);
		order: 2;
		width: fit-content;
		white-space: nowrap;
		border: none;
	}

	.button-container .button:nth-child(2):hover {
		background-color: var(--yellow-200);
		border: none;
	}

	.active-whitebutton .button:nth-child(1) {
		color: white;
		border-color: white;
	}
	.active-whitebutton .button:nth-child(1):hover {
		background: none;
		color: var(--grey-75);
		border-color: var(--grey-75);
	}
	.white-active {
		color: var(--grey-50);
	}
}
