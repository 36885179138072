/* Font Family */
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700&display=swap');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	overflow-wrap: normal;
	scroll-behavior: smooth;
}
body {
	min-width: 100%;
	font-family: 'Kanit', sans-serif;
	outline: none;
	font-style: normal;
	font-weight: var(--font-medium);
}

/* Overriding browser style */
a {
	text-decoration: none;
	color: var(--grey-300);
}
p {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

h1 {
	margin-block-start: 0;
	margin-block-end: 0;
}

h6 {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

.wrapper {
	/* This is to center the website on larger screens without distorting the layout*/
	max-width: 1400px;
	margin: 0 auto;
	padding: 0 1rem;
}

/* Extra Large Screen/Laptop Styling */
@media only screen and (min-width: 1200px) {
	.wrapper {
		padding: 0 5rem;
	}
}

/* Core CSS Variables for Design System */
:root {
	/* Colors */
	/* Grey */
	--grey-50: #e8e8e8;
	--grey-75: #a2a2a2;
	--grey-100: #7c7c7b;
	--grey-200: #434342;
	--grey-300: #1d1d1b;
	--grey-400: #141413;
	--grey-500: #121210;
	/* Green */
	--green-50: #e7f4ec;
	--green-75: #9dd4b0;
	--green-100: #74c290;
	--green-200: #39a760;
	--green-300: #10953f;
	--green-400: #0b682c;
	--green-500: #0a5b26;
	/* Yellow */
	--yellow-50: #fef4e7;
	--yellow-75: #f9d19b;
	--yellow-100: #f6bf71;
	--yellow-200: #f3a334;
	--yellow-300: #f0900a;
	--yellow-400: #a86507;
	--yellow-500: #925806;
	/* Red */
	--red-50: #fce6e8;
	--red-75: #f29aa1;
	--red-100: #ed707a;
	--red-200: #e53240;
	--red-300: #e00819;
	--red-400: #9d0612;
	--red-500: #89050f;
	/* Brown */
	--brown-50: #fdfcfa;
	--brown-75: #b3a7a1;
	--brown-100: #93837a;
	--brown-200: #654d41;
	--brown-300: #45291a;
	--brown-400: #301d12;
	--brown-500: #2a1910;
	--black: #000;
	--white: #fff;

	/* Typography */
	/* 10px,0.5556rem */
	--body-caption-2: 10px;
	/* 14px ,0.778rem*/
	--body-caption-1: 14px;
	/* 18px, 1rem */
	--body-text: 18px;
	/* 24px,  1.333rem*/
	--h6: 24px;
	/* 32px ,1.1778rem*/
	--h5: 32px;
	/* 43px ,2.389rem*/
	--h4: 43px;
	/* 57px ,3.167rem*/
	--h3: 57px;
	/* 76px ,4.222rem*/
	--h2: 76px;
	/* 101px,5.611rem */
	--h1: 101px;
	/* Font Weights */
	--font-normal: 400;
	--font-medium: 500;
	--font-semibold: 600;
	--font-bold: 700;

	/*
  Screen Sizes/Breakpoints
    min - Minimum breakpoint
    max- maximum breakpoint
    ref- https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/
  */
	--mobile-min: 320px;
	--mobile-max: 480px;
	--tablet-min: 481px;
	--tablet-max: 768px;
	--laptop-min: 769px;
	--laptop-max: 1024px;
	--desktop-min: 1025px;
	--desktop-max: 1200px;
	--tv-min: 1200px;
}

.leaflet-popup-content {
	min-width: max-content;
}
