.blog-list {
	display: grid;
	grid-template-columns: 1fr;
	gap: 10px;
	width: 100%;
	margin-top: 20px;
}

.blog-card {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.blog-card h1 {
	font-weight: var(--font-medium);
	font-size: var(--h5);
	line-height: 48px;
	color: var(--grey-300);
}

.blog-card p {
	font-size: var(--body-text);
	font-weight: var(--font-normal);
	line-height: 32px;
	color: var(--grey-300);
}

.blog-card img {
	width: 100%;
}
.blog-card button {
	align-self: self-start;
	color: var(--yellow-300);
	background-color: transparent;
	border: 1px solid var(--yellow-300);
}

.blog-card button:hover {
	color: var(--white);
}

/* Tablet and Laptop Styling */
@media only screen and (min-width: 769px) {
	.blog-list {
		grid-template-columns: repeat(2, 1fr);
	}

	.blog-card p {
		font-size: var(--h6);
	}
	.blog-card h1 {
		font-weight: var(--font-semibold);
		line-height: 72px;
		font-size: var(--h5);
	}
}
