.map-section {
    min-height: 450px;
    width: 100%;
    border: 2px solid #000000;
    z-index: 0;
}

.popup-container{
    display: flex;
    height:200px;
    flex-direction: column;
    gap: 8px;
    width: max-content;
}


.image-container{
    height: 90px;
    flex: 1;
    object-fit: contain;
}

.location-image{
    height: 100%;
    width: 100%;
}

.location-text {
    display: flex;
    flex-direction: column;
    gap:4px;
}

.location-button-text{
    margin-bottom: 0px;
}

.location-text > a {
    border: none;
}

.location-text h6{
    font-size: var(--body-text);
    font-weight: var(--font-normal);
    color: var(--grey-300);
    line-height: 36px;
}


.location-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 4px;
    width: fit-content;
    font-size: var(--body-caption-1);
    font-weight: var(--font-medium);
    line-height: 24px;
    border: 1px solid var(--yellow-300);
    gap: 2px;
    color: var(--yellow-300);
    background-color: var(--yellow-50);
    cursor: pointer;
}
.location-button:hover{
    color: var(--yellow-100);
    border: 1px solid var(--yellow-100);
}

.popup-image{
  flex: 1;
}

@media only screen and (min-width: 900px) {
    .location-text h6{
    font-size: var(--h6);
    font-weight: var(--font-semibold);
    color: var(--grey-300);
    line-height: 36px;
}

.location-button{
    font-size: var(--body-caption-1);
    font-weight: var(--font-medium);
    line-height: 24px;
}
.location-image{
    height: auto;
    width: auto;
}

.popup-container{
    display: flex;
    flex-direction: row;
    gap: 22px;
    width: max-content;
    height: fit-content;
}

.location-text {
    display: flex;
    flex-direction: column;
    gap:16px;
}

}
