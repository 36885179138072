.hompage-top-section {
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.home-bg-picture {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	filter: brightness(50%);
	z-index: 0;
}

.homepage {
	display: flex;
	flex-direction: column;
	gap: 5rem 0;
	margin-bottom: 5rem;
}

@media only screen and (min-width: 759px) {
	.homepage {
		gap: 10rem 0;
	}

	.hompage-top-section {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.home-bg-picture {
		height: 100%;
	}
}

/* Extra Large Screen/Laptop Styling */
@media only screen and (min-width: 1200px) {
	.homepage {
		gap: 15rem 0;
	}
}
