.page-header-container {
	display: flex;
	flex-direction: column;
	width: inherit;
	justify-content: space-between;
	gap: 3rem;
	margin-top: 6rem;
}

.page-header-container > h3 {
	font-size: var(--h5);
	font-weight: var(--font-medium);
	line-height: 48px;
	color: var(--black);
	width: 312px;
}

.page-image-wrapper > .image {
	border-radius: 32px;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.page-image-wrapper {
	flex: 1;
	border-radius: 32px;
}

/* Large Screen/Laptop Styling */
@media only screen and (min-width: 769px) {
	.page-header-container h3 {
		font-weight: var(--font-semibold);
		font-size: var(--h3);
		line-height: 72px;
		width: 318px;
	}
	.page-header-container {
		flex-direction: row;
		align-items: center;
		gap: 106px;
		width: 100%;
		height: 416px;
	}

	.page-image-wrapper {
		object-fit: contain;
		flex: auto;
		border-radius: 32px;
	}
	.page-header-container > .image {
		height: inherit;
		width: inherit;
		border-radius: 32px;
	}
}
