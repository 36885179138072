.registration {
	display: flex;
	flex-direction: column;
	color: var(--grey-300);
	gap: 3rem;
}

.registration-content > h2 {
	font-weight: var(--font-medium);
	font-size: var(--h5);
	line-height: 48px;
	text-align: center;
	margin-bottom: 1rem;
}

.registration-content > p {
	font-weight: var(--font-normal);
	font-size: var(--body-text);
	line-height: 32px;
	text-align: justify;
}

/* Laptop Styling */
@media only screen and (min-width: 769px) {
	.registration-content > h2 {
		font-weight: var(--font-semibold);
		line-height: 72px;
		font-size: var(--h3);
	}

	.registration-content > p {
		font-size: var(--h6);
		line-height: 38px;
		text-align: center;
	}
}

/* Larger screens */
@media only screen and (min-width: 1000px) {
	.registration-content > p {
		max-width: 80%;
		margin: auto;
	}
}
