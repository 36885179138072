
.language-section {
    display: flex;
    width: inherit;
    position: relative;
    flex-direction: row;
    gap: 11px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out .3s;
  }

  .drop{
    transform: rotate(180deg);
  }

  .language-text {
    font-size: var(--body-caption-1);
    font-weight: var(--font-medium);
    color: var(--grey-300);
  }

  .language-dropdown{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    justify-content: flex-start;
    list-style: none;
    top:2rem;
    border-radius: 4px;
    box-shadow: 0px 4px 16px rgba(52, 52, 52, 0.1);
    background-color: var(--white);
    margin: 0px;
  }

  .language-dropdown > li {
    font-size: var(--body-text);
    font-weight: var(--font-normal);
    line-height: 38px;
  }

  .dropdown-mobile {
    display: block;
  }
  .dropdown{
    display: none;
  }

@media only screen and (min-width: 769px) {

.language-section {
    order: 2;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
  }

  .language-text {
    font-size: var(--body-text);
    font-weight: var(--font-medium);
    color: var(--grey-300);
    width: 20px;
  }

  .language-dropdown{
    position: absolute;
    list-style: none;
    right: 0;
    padding:  16px 24px;
    z-index: 20;
    top:2rem;
    line-height: 38px;

  }

  .language-dropdown > li {
    font-size: var(--h6);
    margin: 0;
  }

  .language-dropdown > li:hover {
    color: var(--yellow-300);
    cursor: pointer;
  }
  .activate-white{
    color: var(--grey-50);
  }
  .dropdown{
    display: block;
  }
  .dropdown-mobile {
    display: none;
  }
}