.notFound {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10rem;
}

.links {
	display: flex;
	flex-direction: column;
}

.links > * {
	color: var(--green-500);
}
