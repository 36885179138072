.homepage {
	display: flex;
	flex-direction: column;
	gap: 5rem 0;
	padding: 0 1rem;
	margin-bottom: 5rem;
}

.blog-detail-page {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin-top: 40px;
	text-align: left;
}

.blog-detail-page h1 {
	font-weight: var(--font-medium);
	font-size: var(--h5);
	line-height: 48px;
	color: var(--grey-300);
}
.blog-detail-page h3 {
	font-weight: var(--font-normal);
	font-size: var(--body-caption);
	color: var(--grey-300);
}

/* Large Screen/Laptop Styling */
@media only screen and (min-width: 769px) {
	.homepage {
		padding: 0 3rem;
		gap: 10rem 0;
	}
	.blog-detail-page h1 {
		font-weight: var(--font-semibold);
		line-height: 72px;
		font-size: var(--h5);
	}
	.blog-detail-page {
		gap: 100px;
		margin-top: 100px;
	}
}

/* Extra Large Screen/Laptop Styling */
@media only screen and (min-width: 1200px) {
	.homepage {
		padding: 0 5rem;
		gap: 15rem 0;
	}
}
