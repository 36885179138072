.webmarketing-section {
	margin-top: 50px;
}

.webmarketing-text > h2 {
	font-weight: var(--font-semibold);
	font-size: 24px;
	line-height: 38px;
	background: linear-gradient(
		105.32deg,
		#f0900a -19.51%,
		#ee900a -11.89%,
		#e8900c -4.26%,
		#de900e 3.36%,
		#cf9112 10.98%,
		#bc9116 18.6%,
		#a6921c 26.23%,
		#8d9221 33.85%,
		#739328 41.47%,
		#5a932d 49.1%,
		#449433 56.72%,
		#319437 64.34%,
		#22953b 71.97%,
		#18953d 79.59%,
		#12953f 87.21%,
		#10953f 94.83%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-align: center;
}

.webmarketing-image {
	width: 250px;
	height: 260px;
}
.webmarketing-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.webmarketing-image {
	width: 281px;
	height: 278.38px;
}

/* Laptop Styling */

@media only screen and (min-width: 900px) {
	.webmarketing-text > h2 {
		font-weight: var(--font-medium);
		line-height: 72px;
		font-size: var(--h3);
		width: inherit;
		top: -6rem;
		text-align: start;
		z-index: 0;
		position: absolute;
	}

	.webmarketing-text {
		width: 773px;
		position: relative;
		margin-top: 3rem;
	}
	.webmarketing-content {
		position: relative;
		display: flex;
		flex-direction: row;
	}

	.webmarketing-image {
		width: fit-content;
		height: fit-content;
		left: 0;
	}
}
