.jumbotron-container {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	min-width: fit-content;
	gap: 56px;
	margin-top: 12rem;
	z-index: 40;
}

.conference-year {
	font-size: var(--h6);
	font-weight: var(--font-medium);
	line-height: 38px;
	color: var(--yellow-200);
}
.conference-theme {
	font-size: var(--h5);
	font-weight: var(--font-semibold);
	color: var(--grey-50);
	line-height: 48px;
	max-width: 90%;
}
.conference-time-location {
	display: flex;
	flex-direction: row;
	gap: 16px;
	width: 100%;
	align-items: center;
	line-height: 24px;
	flex-wrap: wrap;
}
.conference-time-location hr {
	width: 4px;
	height: inherit;
	background-color: var(--yellow-100);
}
.conference-time-location span {
	line-height: 24px;
	color: var(--grey-50);
	font-size: var(--body-caption-1);
	margin: 0;
}

.jumbotron-buttons {
	display: flex;
	flex-direction: column;
	width: fit-content;
	gap: 20px;
	padding-bottom: 2rem;
}

.jumbotron-buttons .button {
	color: var(--white);
}
.logo-container {
	display: flex;
	align-items: center;
}

.countdown{
	color: #fff;

}

@media only screen and (max-width: 280px) {
	.conference-theme {
		width: 100%;
	}
	.conference-time-location {
		gap: 4px;
		
	}
	.conference-time-location span {
		font-size: var(--body-caption-2);
	}
	.jumbotron-container {
		margin-top: 8rem;
		gap: 20px;
	}
}
.line {
	height: 20px;
}

@media only screen and (min-width: 930px) {
	.jumbotron-container {
		width: 100%;
		height: 100%;
		gap: 60px;
		margin-top: 12%;
	}

	.line {
		height: 38px;
	}
	.jumbotron-content {
		display: flex;
		flex-direction: column;
	}

	.conference-year {
		font-size: var(--h4);
		font-weight: var(--font-medium);
		line-height: 56px;
		color: var(--yellow-200);
	}
	.conference-theme {
		font-size: var(--h3);
		font-weight: var(--font-semibold);
		color: var(--grey-50);
		line-height: 72px;
		width: 802px;
	}
	.conference-time-location {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		gap: 16px;
		width: max-content;
		line-height: 38px;
	}
	.conference-time-location hr {
		width: 4px;
		height: inherit;
		background-color: var(--yellow-100);
	}
	.conference-time-location span {
		line-height: 38px;
		color: var(--grey-50);
		font-size: var(--h6);
		margin: 0;
	}

	.jumbotron-buttons {
		flex-direction: row;
		flex-wrap: wrap;
	}
}
