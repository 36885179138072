.button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	font-weight: var(--font-normal);
	font-size: var(--body-text);
	line-height: 32px;
	background-color: var(--yellow-300);
	text-align: center;
	cursor: pointer;
	color: var(--grey-300);
	text-decoration: none;
	gap: 5px;
	border-radius: 4px;
	border-color: transparent;
}
.button-link {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	font-weight: var(--font-normal);
	font-size: var(--body-text);
	line-height: 32px;
	background-color: var(--yellow-300);
	text-align: center;
	cursor: pointer;
	color: var(--grey-300);
	text-decoration: none;
	gap: 5px;
	border-radius: 4px;
	border-color: transparent;
}

.button-link > button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-weight: var(--font-normal);
	font-size: var(--body-text);
	line-height: 32px;
	background-color: var(--yellow-300);
	text-align: center;
	cursor: pointer;
	color: white;
	text-decoration: none;
	gap: 5px;
	border-radius: 4px;
	border-color: transparent;
}

.button:hover {
	background-color: var(--yellow-200);
}

/* Tablet aStyling */
@media only screen and (min-width: 769px) {
	.button {
		font-weight: var(--font-semibold);
		padding: 8px 20px;
	}
}
