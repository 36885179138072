.aboutpage-section {
	padding: 0 1rem;
	display: flex;
	gap: 72px;
	flex-direction: column;
	margin-top: 96px;
}

.aboutpage-container {
	display: flex;
	flex-direction: column;
	width: inherit;
	justify-content: space-between;
	gap: 3rem;
}

.aboutpage-container > h3 {
	font-size: var(--h5);
	font-weight: var(--font-medium);
	line-height: 48px;
	color: var(--black);
	width: 312px;
}
.aboutpage-image-wrapper > .image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 32px;
}

.aboutpage-image-wrapper {
	flex-grow: 1;
	border-radius: 32px;
}
.about-OSMAfrica {
	display: flex;
	flex-direction: column;
	margin-top: 96px;
}

.about-OSMAfrica > h5 {
	font-size: var(--h5);
	font-weight: var(--font-medium);
	line-height: 48px;
}

.about-OSMAfrica > p {
	font-size: var(--h6);
	font-weight: var(--font-normal);
	line-height: 38px;
}

/* Large Screen/Laptop Styling */
@media only screen and (min-width: 769px) {
	.aboutpage-section {
		padding: 0 5rem;
		width: 100%;
		gap: 124px 0;
		margin-top: 5rem;
	}

	.aboutpage-container h3 {
		font-weight: var(--font-semibold);
		font-size: var(--h3);
		line-height: 72px;
	}
	.aboutpage-container {
		flex-direction: row;
		align-items: center;
		width: 100%;
	}
	.aboutpage-container > .image {
		height: auto;
		width: auto;
		border-radius: 32px;
	}
	.about-OSMAfrica > h5 {
		font-size: var(--h5);
		font-weight: var(--font-medium);
		line-height: 48px;
	}

	.about-OSMAfrica > p {
		font-size: var(--h6);
		font-weight: var(--font-normal);
		line-height: 38px;
	}
}

