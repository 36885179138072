.homepage {
	display: flex;
	flex-direction: column;
	gap: 5rem 0;
	padding: 0 1rem;
	margin-bottom: 5rem;
}

.programme{
	max-width: 100%;
	overflow: scroll;
}
/* Large Screen/Laptop Styling */
@media only screen and (min-width: 769px) {
	.homepage {
		padding: 0 3rem;
		gap: 10rem 0;
	}
}

/* Extra Large Screen/Laptop Styling */
@media only screen and (min-width: 1200px) {
	.homepage {
		padding: 0 5rem;
		gap: 15rem 0;
	}
}
